import { useEffect, useMemo, useState, useContext } from 'react';
import { Box } from '@mui/material';
import cx from 'classnames';
import { NextRouter } from 'next/router';
import { useContextualRouting } from 'next-use-contextual-routing';
import useSWR from 'swr';
import { createParamString, trackEvent, type Units } from '@surfline/web-common';

import { BRAZE_CONTENT_CARDS } from 'common/constants';
import { usePageCall } from 'common/hooks/usePageCall';
import AnonHero from 'components/AnonHero';
import { PLAYED_WEBCAM } from 'components/CamPlayerV2/useTrackCamPlayback';
import ContentCard from 'components/ContentCard';
import EditorialCarousel from 'components/EditorialCarousel';
import EventsAndAlerts from 'components/EventsAndAlerts';
import FavoritesCarouselContent from 'components/FavoritesCarousel';
import GoogleDFP from 'components/GoogleDFP';
import HomePageMeta from 'components/HomePageMeta';
import ModalContextual from 'components/ModalContextual';
import SwellEventCardContainer from 'components/SwellEventCardContainer/SwellEventCardContainer';
import Favorites from 'containers/Favorites';
import FavoritesForecastOutlook from 'containers/FavoritesForecastOutlook/FavoritesForecastOutlook';
import { ForecastOutlookContextProvider } from 'contexts/ForecastOutlookContext';
import { PageContextProvider } from 'contexts/PageContext';
import { UserTypeContext } from 'contexts/UserTypeContext';
import { VideoJSContextProvider } from 'contexts/VideoJSContext';
import type { SwellEvent } from 'types/swellEvents';
import loadAdConfig from 'utils/adConfig';
import swellEventsFetch from 'utils/swellEventsFetch';
import { useUserPermissionStatus } from 'selectors/user';

import styles from './Homepage.module.scss';

interface HomepageProps {
  geotarget: string;
  router: NextRouter;
  colorSchemeIndex?: number;
  userCountryCode: string;
  units: Units;
}

const MODAL_OPEN_PATHS = ['/favorites'];

const Homepage: React.FC<HomepageProps> = ({
  geotarget,
  userCountryCode,
  router,
  colorSchemeIndex,
  units,
}) => {
  const { makeContextualHref, returnHref } = useContextualRouting();
  const { asPath, query } = router;
  const [modalOpen, setModalOpen] = useState(false);
  const { userType, entitlements, showAds } = useContext(UserTypeContext);
  const { hasCoreForecastPermissions } = useUserPermissionStatus();

  useEffect(() => {
    const modalShouldBeOpen = MODAL_OPEN_PATHS.includes(asPath.split('?')[0]);
    setModalOpen(modalShouldBeOpen);
  }, [asPath]);

  const segmentProperties = useMemo(
    () => ({
      userType,
      category: 'home',
      channel: 'home',
      subCategory: 'personalized',
    }),
    [userType],
  );

  usePageCall('Home', segmentProperties, !!router);

  useEffect(() => {
    trackEvent(PLAYED_WEBCAM, {
      category: 'Homepage Spot Preview Card',
      premiumCam: false,
      spotName: 'dummy',
      camName: 'dummy',
      camId: 'dummy',
      favorite: false,
      spotId: 'dummy',
      videoType: 'live',
    });
  }, []);

  const { data: swellEvents } = useSWR(
    `/feed/events?${createParamString({ geotarget })}`,
    swellEventsFetch,
  );

  const onClickSwellEventCard = (swellEvent: SwellEvent) => {
    trackEvent('Clicked Swell Alert Card', {
      title: swellEvent.name,
      contentType: 'Swell Alert',
      basins: swellEvent.basins.join(','),
      locationCategory: 'Homepage - Top',
      destinationUrl: swellEvent.permalink,
    });
  };

  const adTarget = useMemo(() => [['qaFlag', !!query?.qaFlag]], [query?.qaFlag]);

  const renderSwellEvents = () => (
    <SwellEventCardContainer
      events={swellEvents || []}
      onClickCard={(swellEvent) => onClickSwellEventCard(swellEvent)}
    />
  );

  return (
    <PageContextProvider pageName="Home">
      <HomePageMeta />
      {swellEvents?.length && !userType?.isAnonymous ? (
        <section className="sl-section-container" data-testid="homepage-swell-events-top">
          {renderSwellEvents()}
        </section>
      ) : null}
      <section className="sl-section-container">
        <ContentCard
          card={{ name: BRAZE_CONTENT_CARDS.HOMEPAGE_HEADER, extras: null }}
          className={styles.contentCardHeader}
          testId="banner-braze-card-homepage-header"
        />
      </section>
      <Box>
        <ForecastOutlookContextProvider
          hasCoreForecastPermissions={hasCoreForecastPermissions}
          surfHeightUnit={units?.surfHeight}
        >
          <VideoJSContextProvider>
            <ModalContextual open={modalOpen} returnHref={returnHref}>
              <PageContextProvider pageName="Favorites Modal">
                <Favorites surfHeightUnit={units?.surfHeight} userCountryCode={userCountryCode} />
              </PageContextProvider>
            </ModalContextual>
            {userType.isAnonymous ? (
              <AnonHero
                makeContextualHref={makeContextualHref}
                userCountryCode={userCountryCode}
                geoTarget={geotarget}
                colorSchemeIndex={colorSchemeIndex}
              />
            ) : (
              <FavoritesCarouselContent
                makeContextualHref={makeContextualHref}
                modalOpen={modalOpen}
                userCountryCode={userCountryCode}
              />
            )}
          </VideoJSContextProvider>
          <section className="sl-section-container" style={{ marginTop: '40px' }}>
            <ContentCard
              card={{ name: BRAZE_CONTENT_CARDS.HOMEPAGE_OUTLOOK, extras: null }}
              className={styles.contentCardOutlook}
              testId="banner-braze-card-homepage-outlook"
            />
          </section>
          <FavoritesForecastOutlook makeContextualHref={makeContextualHref} geotarget={geotarget} />
        </ForecastOutlookContextProvider>
        {showAds && (
          <div className={styles.ad} data-testid="homepage-ad">
            <GoogleDFP
              adConfig={loadAdConfig(
                'homepage_feed_top',
                adTarget,
                entitlements,
                !userType.isAnonymous,
                null,
              )}
            />
          </div>
        )}
        {swellEvents?.length && userType?.isAnonymous ? (
          <EventsAndAlerts>{renderSwellEvents()}</EventsAndAlerts>
        ) : null}
        <EditorialCarousel geotarget={geotarget} />
        {showAds && (
          <div className={cx(styles.ad, styles.bottomAd)} data-testid="homepage-ad">
            <GoogleDFP
              adConfig={loadAdConfig(
                'homepage_feed_bottom',
                adTarget,
                entitlements,
                !userType.isAnonymous,
                null,
              )}
            />
          </div>
        )}
      </Box>
    </PageContextProvider>
  );
};

export default Homepage;
